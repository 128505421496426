// Fonte

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Outfit:wght@300;400;700&display=swap');

// Redefinir css para todos os componentes HTML

* {
  font-family: 'Source Sans Pro';
}

@supports (scrollbar-width: auto) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #dbdbdb #f2f2f2;
  }
}

@supports selector(::-webkit-scrollbar) {
  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 0px;
    border: 0px dashed #ffffff;
  }
}

// title

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Outfit';
}

// Body

body {
  background-color: #f9f9f9;
  margin: 0px;
  color: #262626;
}

body,
app-root {
  width: 100vw;
  height: 100vh;
}

/* Configuração monster-web-kit-angular */

@import url('../node_modules/@techramper/monster-web-kit-core/dist/utils/css/monster.margin.css');
@import url('../node_modules/@techramper/monster-web-kit-core/dist/utils/css/monster.padding.css');

:root {
  // BORDER

  --ms-border-width: 1px;
  --ms-border-style: solid;
  --ms-border-color: #dfdfdf;

  // TEXT

  --ms-font-family: 'Source Sans Pro';
  --ms-font-family-title: 'Outfit';

  --ms-text-color: #262626;
  --ms-text-color-disabled: #bfbfbf;

  // TITLE

  --ms-title-font-size-1: 24px;
  --ms-title-font-size-2: 24px;
  --ms-title-font-size-3: 20px;
  --ms-title-font-size-4: 20px;
  --ms-title-font-size-5: 16px;
  --ms-title-font-size-6: 16px;

  --ms-title-font-weight-1: 700;
  --ms-title-font-weight-2: 400;
  --ms-title-font-weight-3: 700;
  --ms-title-font-weight-4: 400;
  --ms-title-font-weight-5: 700;
  --ms-title-font-weight-6: 400;

  // BACKGROUND

  --ms-background-color: #f9f9fb;
  --ms-background-color-rgb: 229, 229, 229;

  // MS-TABLE

  --ms-table-header-background-color: #ffffff;
  --ms-table-header-text-color: #8c8c8c;
  --ms-table-row-background-color: #f9f9fb;
  --ms-table-row-hover-color: #eeeff1;
  --ms-table-row-select-color: #eeeff1;
  --ms-table-row-select-border-color: #bac0cc;
  --ms-table-row-disabled-text-color: #bfbfbf;

  // MS-TOGGLE

  --ms-toggle-disabled-background-color: #f0f0f0;

  // MS-BUTTON

  --ms-button-disabled-background-color: #f0f0f0;
  --ms-button-disabled-border-color: #bfbfbf;
  --ms-button-disabled-color: #bfbfbf;

  // MS-INPUT

  --ms-input-label-color: #262626;
  --ms-input-border-color: #d9d9d9;
  --ms-input-placeholder-color: #8c8c8c;
  --ms-input-disabled-color: #f0f0f0;
  --ms-input-input-error-color: #e01e5a;

  // MS-ALERT

  --ms-alert-info-border-color: #3a96c6;
  --ms-alert-warning-border-color: #f9d04a;
  --ms-alert-success-border-color: #87c441;
  --ms-alert-error-border-color: #e01e5a;

  --ms-alert-info-background-color: #e6f7ff;
  --ms-alert-warning-background-color: #fffbe6;
  --ms-alert-success-background-color: #f6ffed;
  --ms-alert-error-background-color: #fff2f0;

  --ms-alert-info-text-color: #262626;
  --ms-alert-warning-text-color: #262626;
  --ms-alert-success-text-color: #262626;
  --ms-alert-error-text-color: #262626;

  // MS-TAG

  --ms-tag-green-background-color: #e4fbee;
  --ms-tag-green-border-color: #aff3cb;
  --ms-tag-green-text-color: #0d592c;
  --ms-tag-green-icon-color: #1bb258;

  --ms-tag-orange-background-color: #fdf5e3;
  --ms-tag-orange-border-color: #fae9c2;
  --ms-tag-orange-text-color: #503a06;
  --ms-tag-orange-icon-color: #efb734;

  --ms-tag-red-background-color: #fbe9e9;
  --ms-tag-red-border-color: #f6cbcb;
  --ms-tag-red-text-color: #4f0d0d;
  --ms-tag-red-icon-color: #e04747;

  --ms-tag-gray-background-color: #e7eaee;
  --ms-tag-gray-border-color: #b9c0cc;
  --ms-tag-gray-text-color: #262626;
  --ms-tag-gray-icon-color: #7a7a7a;

  --ms-tag-disabled-background-color: #f9f9fb;
  --ms-tag-disabled-border-color: #dddde9;
  --ms-tag-disabled-text-color: #bfbfbf;
  --ms-tag-disabled-icon-color: #bfbfbf;

  // MS-SEARCH-MULTIPLE

  --ms-search-multiple-tag-background-color: #e7eaee;
  --ms-search-multiple-tag-text-color: #262626;
  --ms-search-multiple-tag-icon-color: #7a7a7a;
  --ms-search-multiple-count-text-color: #bfbfbf;
  --ms-search-multiple-limit-text-color: #bfbfbf;

  // MS-SELECT

  --ms-select-tag-background-color: #e7eaee;
  --ms-select-tag-text-color: #262626;
  --ms-select-tag-icon-color: #7a7a7a;
  --ms-select-limit-text-color: #bfbfbf;
  --ms-select-count-text-color: #262626;

  // MS-BUTTON
  // MS-CARD
  // MS-ICON
  // MS-LOADING
  // MS-DROPDOWN
  // MS-NAVBAR
  // MS-CALENDAR
  // MS-DATEPICKER
  // MS-TOGGLE

  --ms-color-primary: #172f57;
  --ms-color-primary-rgb: 23, 47, 87;
  --ms-color-primary-contrast: #fff;
  --ms-color-primary-contrast-rgb: 255, 255, 255;
  --ms-color-primary-hover: #33486b;
  --ms-color-primary-active: #4f617f;
  --ms-color-primary-hover-inverted: #e3e6eb;
  --ms-color-primary-active-inverted: #c7cdd7;

  --ms-color-secondary: #3a96c6;
  --ms-color-secondary-rgb: 58, 150, 198;
  --ms-color-secondary-contrast: #fff;
  --ms-color-secondary-contrast-rgb: 255, 255, 255;
  --ms-color-secondary-hover: #3a96c6;
  --ms-color-secondary-active: #3a96c6;
  --ms-color-secondary-hover-inverted: #e8f8ff;
  --ms-color-secondary-active-inverted: #d0f0ff;

  --ms-color-success: #2dd36f;
  --ms-color-success-rgb: 45, 211, 111;
  --ms-color-success-contrast: #fff;
  --ms-color-success-contrast-rgb: 255, 255, 255;
  --ms-color-success-hover: #46d880;
  --ms-color-success-active: #5fde92;
  --ms-color-success-hover-inverted: #e6faee;
  --ms-color-success-active-inverted: #cdf4dc;

  --ms-color-warning: #f9d04a;
  --ms-color-warning-rgb: 249, 208, 74;
  --ms-color-warning-contrast: #fff;
  --ms-color-warning-contrast-rgb: 255, 255, 255;
  --ms-color-warning-hover: #ffcb27;
  --ms-color-warning-active: #ffd244;
  --ms-color-warning-hover-inverted: #fff8e1;
  --ms-color-warning-active-inverted: #fff1c4;

  --ms-color-danger: #e01e5a;
  --ms-color-danger-rgb: 235, 68, 90;
  --ms-color-danger-contrast: #fff;
  --ms-color-danger-contrast-rgb: 255, 255, 255;
  --ms-color-danger-hover: #ed5a6e;
  --ms-color-danger-active: #f07182;
  --ms-color-danger-hover-inverted: #fde9eb;
  --ms-color-danger-active-inverted: #fad2d7;

  --ms-color-dark: #000000;
  --ms-color-dark-rgb: 0, 0, 0;
  --ms-color-dark-contrast: #fff;
  --ms-color-dark-contrast-rgb: 255, 255, 255;
  --ms-color-dark-hover: #1f1f1f;
  --ms-color-dark-active: #3d3d3d;
  --ms-color-dark-hover-inverted: #e0e0e0;
  --ms-color-dark-active-inverted: #c2c2c2;

  --ms-color-medium: #bfbfbf;
  --ms-color-medium-rgb: 191, 191, 191;
  --ms-color-medium-contrast: #fff;
  --ms-color-medium-contrast-rgb: 255, 255, 255;
  --ms-color-medium-hover: #9fa1a8;
  --ms-color-medium-active: #acaeb4;
  --ms-color-medium-hover-inverted: #f2f2f3;
  --ms-color-medium-active-inverted: #e5e5e7;

  --ms-color-light: #ffffff;
  --ms-color-light-rgb: 255, 255, 255;
  --ms-color-light-contrast: #000;
  --ms-color-light-contrast-rgb: 0, 0, 0;
  --ms-color-light-hover: #f3f4f6;
  --ms-color-light-active: #e7eaee;
  --ms-color-light-hover-inverted: #e0e0e0;
  --ms-color-light-active-inverted: #c2c2c2;

  --ms-color-grayscale: #e7eaee;
  --ms-color-grayscale-rgb: 231, 234, 238;
  --ms-color-grayscale-contrast: #000;
  --ms-color-grayscale-contrast-rgb: 0, 0, 0;
  --ms-color-grayscale-hover: #eaedf0;
  --ms-color-grayscale-active: #edeff2;
  --ms-color-grayscale-hover-inverted: #fcfcfd;
  --ms-color-grayscale-active-inverted: #f9fafb;
}
